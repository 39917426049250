<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        question-components-header

        div(class="question-title") アンケートタイトル
          span(class="caution-t") *
            span(class="m-l" style="color: black") ※全角20文字、半角40文字以内
          input(v-model.trim="data.title" class="input-g-w-border")
        
        div 内容               
          span(class="m-l" style="color: black") ※全角20文字、半角40文字以内
        div(class="question-item")
          div(class="question-item-number") 1
            span(class="caution-t") *
          input(v-model.trim="data.options[0]" class="input-g-border")
        div(class="question-item")
          div(class="question-item-number") 2
            span(class="caution-t") *
          input(v-model.trim="data.options[1]" class="input-g-border")
        div(class="question-item")
          div(class="question-item-number") 3
          input(v-model.trim="data.options[2]" class="input-g-border")
        div(class="question-item")
          div(class="question-item-number") 4
          input(v-model.trim="data.options[3]" class="input-g-border")
        div(class="question-item")
          div(class="question-item-number") 5
          input(v-model.trim="data.options[4]" class="input-g-border")

        div 終了日時
          span(class="caution-t") *
        input(type="datetime-local" v-model="data.end_time" class="input-g-border")

        div(v-if="!is_s1") ※現在のアンケートを終了するまで
        div(v-if="!is_s1") 新規アンケートの作成はできません

        div(class="caution-t") ※＊は必須項目です
        component-error(:errors="errors" v-if="errors.length")
        div(class="question-bottom-button")
          img(@click="onSave()" class="img-button" src="/img/shitagaki_p.png")
          img(@click="onPublish()" v-if="is_s1" class="img-button" src="/img/jisshi_p.png")
      div(class="content-right")
        pr-banner
    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import QuestionComponentsHeader from "./components/Header";
import { getQuestionListS1, getQuestionListS2, createQuestion, updateQuestion } from '@/api/question'
import { validStringCount } from '@/utils/inputLimit';

export default {
  name: "QuestionIndex",
  components: {
    QuestionComponentsHeader
  },
  data() {
    return {
      data: {
        stage: undefined,
        question_id: undefined,
        title: '',
        options: [undefined,undefined,undefined,undefined,undefined],
        end_time: undefined,
      },
      is_s1: true,
      errors: [],
    }
  },
  props: {},
  computed: {
    validTime(){
      return new Date(this.data.end_time).getTime() > Date.now()
    }
  },
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const ret1 = await getQuestionListS1();
      const ret2 = await getQuestionListS2();
      if (ret1.data.data[0]) {
        this.data.stage = ret1.data.data[0].stage;
        this.data.question_id = ret1.data.data[0].id;
        this.data.title = ret1.data.data[0].title;
        this.data.options = ret1.data.data[0].options;
        if (ret1.data.data[0].end_time) {
          this.data.end_time = this.$moment(ret1.data.data[0].end_time).format("YYYY-MM-DDTHH:mm");
        }
      }
      this.is_s1 = ret2.data.data[0] ? false : true;
    },
    async onSave() {
      this.data.stage = "s1";
      const options = this.data.options.filter(v => v);
      if (options.length > 0) {
        this.data.options = options;
      }
      if (this.data.question_id) {
        await updateQuestion(this.data)
      } else {
        await createQuestion(this.data)
      }
      await this.init();
      this.$message({
        showClose: true,
        message: '完了しました',
        type: 'success'
      });
    },
    async onPublish() {
      this.data.options = this.data.options.filter(v => v);
      if (!this.validationCheck()) { return; }
      this.data.stage = "s2";
      if (this.data.stage && this.data.stage == 's1') {
        await updateQuestion(this.data);
      } else {
        await createQuestion(this.data);
      }
      this.$router.push({ name: 'QuestionNow' });
    },
    validationCheck() {
      this.errors = [];
      let errorStrings = [];
      if (!this.data.title) {
        errorStrings.push('※[アンケートタイトル]を入力してください');
      }
      if (!validStringCount(this.data.title, 40)) {
        errorStrings.push('※[アンケートタイトル]は全角20文字、半角40文字以内で入力してください');
      }
      if (!this.data.options[0] || !this.data.options[1]) {
        errorStrings.push('※[内容]は最低2件入力してください');
      }
      for(let i = 0; i !== this.data.options.length; i++) {
        if (!validStringCount(this.data.options[i], 40)) {
          errorStrings.push('※[内容]は全角20文字、半角40文字以内で入力してください');
          break;
        }
      }
      if (!this.data.end_time) {
        errorStrings.push('※[終了日時]を設定してください\n');
      } else if(!this.validTime){
        errorStrings.push('現在以後の時間を選択してください');
      }

      for (let i = 0; i < this.data.options.length; i++) {
        for (let j = 0; j < this.data.options.length; j++) {
          if (j == i) { continue }
          if (!this.data.options[i]) { continue }

          if (this.data.options[i] === this.data.options[j]){
            errorStrings.push('※[内容]に同じテキストが入っています。再度入力してください');
            i = this.data.options.length; // 親loopを抜ける
            break;
          }
        }
      }
      if (errorStrings.length) {
        this.errors = errorStrings;
        return false;
      }
      return true;
    },
  }
};
</script>
